<template>
  <div class="set-expense-view" v-loading="loading">
    <div class="admin-table-search">
      <el-form label-width="auto" :model="postData" :rules="rules" ref="postForm">
        <el-form-item label="补考费模板:">
          <select-dict
            v-model="postData.templateIdList[0].templateId"
            @change="templateChange"
            :dict="expenseTemplateList"
            label-field="templateName"
            value-field="templateId"
            placeholder="请选择快速设置费用">
          </select-dict>
        </el-form-item>
      </el-form>
    </div>

    <div class="admin-table-list">
      <h2>补考费报销会员服务</h2>
      <el-tabs value="first">
        <el-tab-pane label="科目一" name="first">
          <el-table border :data="expenseTemplateCode.sub1">
            <el-table-column prop="vehicleType" label="类型"></el-table-column>
            <el-table-column prop="income" label="收入金额(元)"></el-table-column>
            <el-table-column prop="compensation" label="补偿金额(元)"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="科目二" name="second">
          <el-table border :data="expenseTemplateCode.sub2">
            <el-table-column prop="vehicleType" label="类型"></el-table-column>
            <el-table-column prop="income" label="收入金额(元)"></el-table-column>
            <el-table-column prop="compensation" label="补偿金额(元)"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="科目三" name="third">
          <el-table border :data="expenseTemplateCode.sub3">
            <el-table-column prop="vehicleType" label="类型"></el-table-column>
            <el-table-column prop="income" label="收入金额(元)"></el-table-column>
            <el-table-column prop="compensation" label="补偿金额(元)"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="其他" name="fourth">
          <el-table border :data="expenseTemplateCode.other">
            <el-table-column prop="label" label="名称"></el-table-column>
            <el-table-column prop="value" label="设置">
              <template slot-scope="scope">
                <el-input disabled v-model="scope.row.value" v-if="scope.row.key != 'returnType'"></el-input>
                <el-select
                  disabled
                  placeholder="请选择"
                  v-model="scope.row.value"
                  v-if="scope.row.key == 'returnType'"
                  style="width: 100%"
                  size="small">
                  <el-option :value="1" label="否"></el-option>
                  <el-option :value="2" label="是"></el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="admin-dialog-btns">
      <el-button @click="submitHandler" type="primary" :loading="loading">确认</el-button>
      <el-button
        @click="
          () => {
            this.$emit('end');
          }
        ">
        取消
      </el-button>
    </div>
  </div>
</template>

<script>
import {drvSchoolSetExpense, expenseTemplateGetList} from '../../api/index';
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      expenseTemplateList: [], // 补考费下拉选数据
      rules: {},
      loading: false,
      templateIds: [1],
      postData: {
        // templateId: '',
        drvSchoolCode: '',
        // autoTemplateIdList
        templateIdList: [{templateId: '', templateType: 1}],
      },
      expenseTemplateCode: {
        sub1: [
          {
            vehicleType: 'A1',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'A2',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'A3',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'B1',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'B2',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'C1',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'C2',
            income: '',
            compensation: '',
          },
        ],
        sub2: [
          {
            vehicleType: 'A1',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'A2',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'A3',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'B1',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'B2',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'C1',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'C2',
            income: '',
            compensation: '',
          },
        ],
        sub3: [
          {
            vehicleType: 'A1',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'A2',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'A3',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'B1',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'B2',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'C1',
            income: '',
            compensation: '',
          },
          {
            vehicleType: 'C2',
            income: '',
            compensation: '',
          },
        ],
        other: [
          {label: '科目一次数上限', value: '', key: 'sub1Limit'},
          {label: '科目二次数上限', value: '', key: 'sub2Limit'},
          {label: '科目三次数上限', value: '', key: 'sub3Limit'},
          {label: '补偿总额限制(元)', value: '', key: 'totalLimit'},
          {label: '保单有效期', value: '', key: 'expiration'},
          {label: '是否退费', value: '', key: 'returnType'},
        ],
      }, // 补考数据 补考费会员服务总模板
      expenseStr: {
        sub1Limit: '科目一次数上限',
        sub2Limit: '科目二次数上限',
        sub3Limit: '科目三次数上限',
        totalLimit: '补偿总额限制(元)',
        expiration: '保单有效期',
        returnType: '是否退费',
      },
    };
  },
  created() {
    this.loading = true;
    expenseTemplateGetList({
      page: 1,
      size: 999,
    }).then((res) => {
      this.loading = false;
      if (res.data.code == 0) {
        res.data.data.forEach((item) => {
          if (item.templateType == 1) {
            this.expenseTemplateList.push(item);
          }
        });
        this.postData.drvSchoolCode = this.$props.row.drvSchoolCode;
        if (this.$props.row.respDrvSchoolTemplateList && this.$props.row.respDrvSchoolTemplateList.length) {
          // this.templateIds.length = 0;
          this.templateIds = [];
          this.$props.row.respDrvSchoolTemplateList.forEach((item) => {
            if (item.templateType == 1) {
              this.templateIds.push(1);
              this.templateChange(item.templateId || '');
            }
          });
        }
      }
    });
  },
  methods: {
    // 补考 select选择
    templateChange(templateId) {
      let template = this.expenseTemplateList.find((item) => {
        if (item.templateId == templateId) {
          return true;
        }
      });
      if (!template) {
        return false;
      }
      this.postData.templateIdList[0].templateId = templateId;
      template = JSON.parse(template.templateCode);
      template.other = Object.keys(template.other).map((key) => {
        return {key: key, label: this.expenseStr[key], value: template.other[key]};
      });
      this.expenseTemplateCode = template;
      this.expenseTemplateCode.other[4].value += '个月';
    },

    submitHandler() {
      if (!this.postData.templateIdList[0].templateId) {
        this.$message.error('请选择模板！');
        return false;
      }
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          this.loading = true;
          let postData = JSON.parse(JSON.stringify(this.postData));
          if (!postData.templateIdList[0].templateId) {
            postData.templateIdList.shift();
          }
          postData.autoTemplateIdList = postData.templateIdList;
          drvSchoolSetExpense(postData).then((res) => {
            this.loading = false;
            if (res.data.code == 0) {
              this.$message.success(res.data.msg);
              this.$emit('success');
              this.$emit('end');
            } else {
              this.$message.error(res.data.msg);
              return false;
            }
          });
        } else {
          this.$message.error('请填写正确信息！');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.set-expense-view {
  max-height: 800px;
  overflow-y: scroll;
  position: relative;
  .admin-dialog-btns {
    margin-top: 25px;
    position: sticky;
    left: 0;
    bottom: 0;
    width: 100%;
    background: white;
  }
}
</style>
